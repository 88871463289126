import type { Currency } from '@whoop/i18n/types/internationalization';
import type { CheckoutPromo } from 'services/generated/membership-service-v2';
import { i18n } from '@whoop/i18n/lang/client';
import { formatExchangeCurrencyAmount } from '@whoop/i18n/utils/priceHelpers';
import type {
  OrderTotalType,
  CartProduct,
  OrderTotal,
  ShippingOption,
  CreatedOrder,
  PromoCodeState,
  AccessoryProduct,
} from '../types';
import type { BillingItemType, ParsedBillingItem } from '../types/cartTypes';
import { getTotalCartDiscount } from './promotionHelpers';

export const findOrderTotal = (
  orderTotals: OrderTotal[],
  totalType: OrderTotalType,
): OrderTotal | undefined => {
  return orderTotals.find((orderTotal) => orderTotal.type === totalType);
};

export const replaceExistingOrderTotal = (
  orderTotals: OrderTotal[],
  existingTotal: OrderTotal,
  newAmount: number,
): OrderTotal[] => {
  const newTotal = { ...existingTotal, display_amount: newAmount };
  orderTotals[orderTotals.indexOf(existingTotal)] = newTotal;

  return orderTotals;
};

export const replaceOrderTotal = (
  orderTotals: OrderTotal[],
  totalType: OrderTotalType,
  newAmount: number,
): OrderTotal[] => {
  const existingTotal = findOrderTotal(orderTotals, totalType);
  if (existingTotal) {
    return replaceExistingOrderTotal(orderTotals, existingTotal, newAmount);
  }
  return orderTotals;
};

interface IGetTotalsFromCart {
  initialTotals: OrderTotal[];
  cartProducts: CartProduct[];
  currency: Currency;
  promoCode?: PromoCodeState;
  promoInfo?: CheckoutPromo;
}

export const getTotalsFromCartProducts = ({
  initialTotals,
  cartProducts,
  currency,
  promoCode,
  promoInfo,
}: IGetTotalsFromCart): OrderTotal[] => {
  let totals = [...initialTotals];
  const hasFamilyMembersip = cartProducts.some(
    (product) => product.item.membership_type === 'family',
  );
  const calculatedSubtotal = cartProducts
    .map((product) => {
      const productCost = product.item.subtotal;
      return (productCost || 0) * (product.quantity || 1);
    })
    .reduce((acc, curr) => acc + curr, 0);

  const calculatedVatOnSubtotal = cartProducts
    .map((product) => (product.item.tax || 0) * (product.quantity || 1))
    .reduce((acc, curr) => acc + curr, 0);

  const subtotalTotal = findOrderTotal(totals, 'subtotal') ?? {
    label: '',
    display_amount: 0,
    type: 'subtotal',
  };

  const subtotalIncludingTax = calculatedSubtotal + calculatedVatOnSubtotal;
  totals = replaceExistingOrderTotal(
    totals,
    subtotalTotal,
    subtotalIncludingTax,
  );

  const cartDiscount =
    promoCode && !hasFamilyMembersip
      ? getTotalCartDiscount(promoCode, cartProducts, currency)
      : 0;

  let itemDiscountsSum = 0;
  cartProducts.forEach((product) => {
    const productCost = product.item.percent_discount
      ? product.item.display_price * (product.item.percent_discount / 100)
      : 0;

    itemDiscountsSum += productCost * (product.quantity || 1);
  });

  if (cartDiscount || itemDiscountsSum) {
    const discountTotal: OrderTotal = {
      label: promoInfo?.referral
        ? i18n.t('cart:discountRAF')
        : i18n.t('cart:discount'),
      display_amount: -(cartDiscount + itemDiscountsSum),
      type: 'discount',
    };
    totals.splice(1, 0, discountTotal);
  }
  return totals;
};

export const getTotalsFromPendingOrder = (
  pendingOrder: CreatedOrder,
  shipping?: ShippingOption,
): OrderTotal[] => {
  let totals = [...pendingOrder.totals];
  if (!shipping) return totals;
  const total = findOrderTotal(totals, 'total');
  // Update total to include shipping tax
  if (total) {
    totals = replaceExistingOrderTotal(
      totals,
      total,
      total.display_amount + (shipping.subtotal + (shipping.tax || 0)),
    );
  }

  // Update shipping to be shippingAmount
  const shippingAmount = shipping.subtotal;
  totals = replaceOrderTotal(totals, 'shipping', shippingAmount);

  const shippingTax = shipping.tax;
  if (!shippingTax) return totals;

  // Update included tax to include shippingTax (update shipping to include the shipping tax)
  // i.e. Shipping = Shipping + Shipping Tax
  // i.e. Included Tax = IncludedTax + Shipping Tax
  const includedTax = findOrderTotal(totals, 'included_tax');
  if (includedTax) {
    const totalsWithUpdatedShipping = replaceOrderTotal(
      totals,
      'shipping',
      shippingAmount + shippingTax,
    );
    const totalsWithUpdatedIncludedTax = replaceOrderTotal(
      totalsWithUpdatedShipping,
      'included_tax',
      includedTax.display_amount + shippingTax,
    );
    return totalsWithUpdatedIncludedTax;
  }

  // Update tax to include shipping tax
  const taxTotal = findOrderTotal(totals, 'tax');
  if (taxTotal) {
    const totalsWithUpdatedTax = replaceExistingOrderTotal(
      totals,
      taxTotal,
      taxTotal.display_amount + shippingTax,
    );
    return totalsWithUpdatedTax;
  }

  return totals;
};

export const isTaxCharged = (totals: OrderTotal[]): boolean => {
  const taxTypes: OrderTotalType[] = ['tax', 'included_tax'];
  return totals.some(
    (total) => taxTypes.includes(total.type) && total.display_amount > 0,
  );
};

export const billingItemsContainsTax = (
  billingItems: ParsedBillingItem[],
): boolean => {
  const taxTypes: BillingItemType[] = ['TAX'];
  return billingItems.some(
    (item) => taxTypes.includes(item.type) && (item.value?.centAmount ?? 0) > 0,
  );
};

export const getOrderTotalInLocalCurrency = (
  totals: OrderTotal[],
  exchangeRate: number,
  exchangeCurrency: string, //Not typing as Currency because mapping is used for supported currencies
  exchangeRoundingNearest: number,
  language: string,
): string => {
  const totalOrderTotal = totals.find((total) => total.type === 'total');
  const totalAmount = totalOrderTotal?.display_amount ?? 0;
  if (totalAmount > 0) {
    return formatExchangeCurrencyAmount(
      totalAmount,
      exchangeRate,
      exchangeCurrency,
      exchangeRoundingNearest,
      language,
    );
  }
  return '';
};

export const shouldOrderTotalsRequireAddressID = ({
  subtotal,
  discount,
}): boolean => {
  // For SA, return false when order subtotal (+tax) - discount < 115000
  const orderTotalIDThresholdSA = 115000;
  return subtotal - Math.abs(discount) >= orderTotalIDThresholdSA;
};

const calculateDuties = (displayPrice: number, dutyRate: number): number => {
  return displayPrice * dutyRate;
};

const calculatePackDuties = (
  packItemAccessoryProducts: AccessoryProduct[],
  accessoryDutyRate: number,
  hardwareDutyRate: number,
  percentDiscount: number | null | undefined = 0,
): number => {
  let estimatedDuties = 0;
  packItemAccessoryProducts.forEach((packItemAccessoryProduct) => {
    const {
      display_price: accessoryDisplayPrice,
      accessory_type: accessoryType,
    } = packItemAccessoryProduct;

    const dutyTaxRate =
      accessoryType === 'hardware' ? hardwareDutyRate : accessoryDutyRate;

    const finalPrice = percentDiscount
      ? accessoryDisplayPrice * (1 - percentDiscount / 100)
      : accessoryDisplayPrice;

    estimatedDuties += calculateDuties(finalPrice, dutyTaxRate);
  });
  return estimatedDuties;
};

// If total line has a non zero value, use that, otherwise sum all the lines
export const getBNPLTotalToUse = (orderTotals: OrderTotal[]): number => {
  const totalLine = findOrderTotal(orderTotals, 'total');
  if (totalLine && totalLine.display_amount !== 0) {
    return totalLine.display_amount;
  }
  return orderTotals.reduce((acc, curr) => acc + curr.display_amount, 0);
};
